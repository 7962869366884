import BringYourBusinessBanner from '../../../components/BringYourBusinessBanner'
import EasyBookingBanner from '../../../components/EasyBookingBanner'
import LessTimeBanner from '../../../components/LessTimeBanner'
import NoWorriesBanner from '../../../components/NoWorriesBanner'

const BASE_CLASS = 'me-userContent'

export default function UserContent() {
  return (
    <div className={BASE_CLASS}>
      <EasyBookingBanner />
      <LessTimeBanner />
      <NoWorriesBanner />
      <BringYourBusinessBanner />
    </div>
  )
}
