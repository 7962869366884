import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../config/firebase'

export default async function signUp({ email, password }) {
  return createUserWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      const user = userCredential.user
      return user
    }
  )
}
