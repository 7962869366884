import { ReactComponent as WifiIcon } from '../../../assets/icons/wifi.svg'
import { ReactComponent as ParkingIcon } from '../../../assets/icons/parking.svg'
import { ReactComponent as PetIcon } from '../../../assets/icons/pet.svg'
import { ReactComponent as CreditCardIcon } from '../../../assets/icons/credit-card.svg'
export const SERVICES = [
  {
    id: 'wifi',
    name: 'Wifi',
    component: <WifiIcon height={36} width={36} />,
  },
  {
    id: 'parking',
    name: 'Parking',
    component: <ParkingIcon height={36} width={36} />,
  },
  {
    id: 'pets',
    name: 'Mascotas',
    component: <PetIcon height={36} width={36} />,
  },
  {
    id: 'creditCard',
    name: 'Tarjeta',
    component: <CreditCardIcon height={36} width={36} />,
  },
]
