import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '../config/firebase'

export default async function uploadImage(file, path) {
  const storageRef = ref(storage, `${path}/${new Date().getTime()}`)
  const url = await uploadBytes(storageRef, file).then((snapshot) => {
    return getDownloadURL(snapshot.ref)
  })
  return url
}
