import './index.scss'
import easyToUseBannerMockup from '../../assets/images/mockup-ipad.jpeg'

const BASE_CLASS = 'me-easyToUserBanner'

export default function EasyToUserBanner() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-text`}>
        <h2 className={`${BASE_CLASS}-text-title`}>
          Ser organizado nunca ha sido tan fácil
        </h2>
        <p className={`${BASE_CLASS}-text-description`}>
          En My-event conocemos la importancia de la organización, por esa razón
          proporcionamos una agenda nítida e intuitiva con posibilidad de
          exportar a Google Calendar.
        </p>
      </div>
      <img
        src={easyToUseBannerMockup}
        alt='My-event mockup'
        title='My-event mockup'
        className={`${BASE_CLASS}-image`}
      />
    </div>
  )
}
