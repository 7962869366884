import { config } from '../config/index'

export default async function getBookingDetail({ bookingId, businessId }) {
  return fetch(
    `${config.baseURl}/bookings?bookingId=${bookingId}&businessId=${businessId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 404) {
        return {
          status: 404,
          message: 'Booking not found',
          data: {},
        }
      }
      return data
    })
}
