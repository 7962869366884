import { config } from '../config'
import { auth } from '../config/firebase'

export default async function createBusiness(business) {
  return fetch(`${config.baseURl}/business`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      businessData: {
        ...business,
        id: auth.currentUser.uid,
      },
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      return data
    })
    .catch((err) => {
      console.log(err)
    })
}
