import { signOut } from 'firebase/auth'
import { auth } from '../config/firebase'

export default async function userSignOut() {
  return signOut(auth)
    .then(() => {
      return true
    })
    .catch((error) => {
      return false
    })
}
