import './index.scss'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import getBusiness from '../../domain/src/business/getBusiness'
import { SCHEDULETRANSLATIONS, SERVICES } from './config'
import LoadingPage from '../LoadingPage'
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { Helmet } from 'react-helmet'

const BASE_CLASS = 'me-businessPage'

export default function BusinessPage() {
  const { businessId } = useParams()
  const [businessInfo, setBusinessInfo] = useState(null)
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const navigate = useNavigate()
  const [selectedProductsError, setSelectedProductsError] = useState(false)

  const handleBook = () => {
    if (selectedProducts.length === 0) {
      setSelectedProductsError(true)
      return
    }

    navigate(`/business/${businessId}/book`, {
      state: {
        products: selectedProducts,
        business: businessInfo,
      },
    })
  }

  useEffect(() => {
    if (selectedProducts.length > 0) {
      setSelectedProductsError(false)
    }
  }, [selectedProducts])

  useEffect(() => {
    if (businessId) {
      getBusiness({
        businessId: businessId,
      }).then(({ data }) => {
        setBusinessInfo(data)
        setProducts(data.products)
      })
    }
  }, [businessId])

  return businessInfo ? (
    <div className={BASE_CLASS}>
      <Helmet title={`Reserva en ${businessInfo.name}`} />
      <div
        className={`${BASE_CLASS}-header`}
        style={{
          backgroundColor: '#ffffff',
          backgroundImage: `url(${businessInfo.backgroundImage})`,
        }}
      >
        <div className={`${BASE_CLASS}-header-overlay`}>
          <img
            onError={(e) =>
              (e.target.src =
                'https://res.cloudinary.com/dkrcosw87/image/upload/v1623340003/placeholder-image.png')
            }
            className={`${BASE_CLASS}-header-logo`}
            src={businessInfo.logo}
            alt={businessInfo.name}
          />
        </div>
      </div>
      <div className={`${BASE_CLASS}-header-info`}>
        <h1 className={`${BASE_CLASS}-header-info-title`}>
          {businessInfo.name}
        </h1>
        <p className={`${BASE_CLASS}-header-info-address`}>
          {businessInfo.address}
        </p>
      </div>
      <div className={`${BASE_CLASS}-contentContainer`}>
        <div className={`${BASE_CLASS}-content`}>
          <div className={`${BASE_CLASS}-businessInfo`}>
            <h3 className={`${BASE_CLASS}-businessInfo-contactTitle`}>
              Información del centro
            </h3>
            <p className={`${BASE_CLASS}-businessInfo-phone`}>
              <PhoneIcon width={24} height={24} fill='#ff9200' />
              {businessInfo.phone?.replace(
                /(\d{3})(\d{3})(\d{3})/,
                '$1 $2 $3'
              ) || 'Sin teléfono'}
            </p>
            <p className={`${BASE_CLASS}-businessInfo-description`}>
              {businessInfo.description}
            </p>
            <div className={`${BASE_CLASS}-schedule`}>
              <h2 className={`${BASE_CLASS}-schedule-title`}>
                Horario del establecimiento
              </h2>
              <table className={`${BASE_CLASS}-schedule-table`}>
                <thead>
                  <tr>
                    <th>Día</th>
                    <th>Horario</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(SCHEDULETRANSLATIONS).map((day, index) => {
                    return (
                      <tr>
                        <td>{SCHEDULETRANSLATIONS[day]}</td>

                        {businessInfo.schedule?.[index]
                          ? businessInfo.schedule?.[index].map((item) =>
                              item === undefined ? (
                                <td>Cerrado</td>
                              ) : (
                                <td>
                                  {item.start} - {item.end}
                                </td>
                              )
                            )
                          : 'Cerrado'}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <h3 className={`${BASE_CLASS}-businessInfo-servicesTitle`}>
              Servicios del centro
            </h3>
            {
              <div className={`${BASE_CLASS}-businessInfo-services`}>
                {businessInfo.services?.map((serviceId) => {
                  const service = SERVICES[serviceId]
                  return (
                    <div className={`${BASE_CLASS}-businessInfo-services-item`}>
                      {service.component}
                      <p
                        className={`${BASE_CLASS}-businessInfo-services-item-name`}
                      >
                        {service.name}
                      </p>
                    </div>
                  )
                })}
              </div>
            }
          </div>
          <div className={`${BASE_CLASS}-products`}>
            <div className={`${BASE_CLASS}-products-header`}>
              <h3 className={`${BASE_CLASS}-products-header-title`}>
                Selecciona los servicios que desees
              </h3>
              <p className={`${BASE_CLASS}-products-header-count`}>
                {products.length} servicios
              </p>
            </div>
            <div className={`${BASE_CLASS}-products-search`}>
              <SearchIcon fill='' height={24} width={24} />
              <input
                className={`${BASE_CLASS}-products-search-input`}
                type='search'
                placeholder='Buscar servicio'
                onChange={(e) => {
                  setProducts(
                    businessInfo.products.filter((product) =>
                      product.name
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  )
                }}
              />
            </div>

            <div className={`${BASE_CLASS}-products-selected`}>
              {selectedProducts.length > 0 && (
                <p className={`${BASE_CLASS}-products-selected-title`}>
                  {selectedProducts.length} servicios seleccionados, duración
                  del servicio de{' '}
                  {selectedProducts.reduce(
                    (acc, product) => acc + product.time,
                    0
                  )}{' '}
                  minutos
                </p>
              )}
              {selectedProductsError && (
                <p className={`${BASE_CLASS}-products-selected-error`}>
                  Debes seleccionar al menos un servicio
                </p>
              )}
            </div>
            <div className={`${BASE_CLASS}-products-list`}>
              {products?.map((product) => (
                <div
                  key={product.id}
                  className={`${BASE_CLASS}-products-list-item`}
                >
                  <div className={`${BASE_CLASS}-products-list-item-leading`}>
                    <input
                      type='checkbox'
                      checked={selectedProducts.includes(product)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedProducts([...selectedProducts, product])
                        } else {
                          setSelectedProducts(
                            selectedProducts.filter(
                              (selectedProduct) =>
                                selectedProduct.id !== product.id
                            )
                          )
                        }
                      }}
                    />
                    <h4 className={`${BASE_CLASS}-products-list-item-title`}>
                      {product.name}
                    </h4>
                    <h4 className={`${BASE_CLASS}-products-list-item-time`}>
                      {product.time} minutos
                    </h4>
                  </div>
                  <p className={`${BASE_CLASS}-products-list-item-price`}>
                    {product.price}€
                  </p>
                </div>
              ))}
            </div>
            <div className={`${BASE_CLASS}-products-actions`}>
              <button
                className={`${BASE_CLASS}-products-button clear`}
                onClick={() => {
                  setSelectedProducts([])
                }}
              >
                Limpiar
              </button>
              <button
                className={`${BASE_CLASS}-products-button`}
                onClick={handleBook}
              >
                Reservar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoadingPage />
  )
}
