import './index.scss'

const BASE_CLASS = 'me-welcomeBanner'

export default function WelcomeBanner({ onMoreInfoButtonClick }) {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-blackFilter`}>
        <div className={`${BASE_CLASS}-content`}>
          <h1 className={`${BASE_CLASS}-title`}>
            Reserva fácilmente con nuestra app en los mejores negocios locales
          </h1>
          <h2 className={`${BASE_CLASS}-subtitle`}>
            My-event te permite agregar tus negocios favoritos y reservar
            servicios fácilmente. Olvídate de las largas llamadas telefónicas y
            espera para hacer una reserva. Reserva en segundos y confirma tus
            reservas con tranquilidad.
          </h2>
          <div className={`${BASE_CLASS}-buttons`}>
            <button className={`${BASE_CLASS}-contactButton`}>
              Solicita una demo
            </button>
            <button
              onClick={onMoreInfoButtonClick}
              className={`${BASE_CLASS}-moreInfoButton`}
            >
              Mas información
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
