import './index.scss'
import logo from '../../assets/logos/my-event-logo-white.svg'

import { Link } from 'react-router-dom'
import { useBusinessContext } from '../../context/BusinessContext/businessContext'
import { auth } from '../../domain/src/config/firebase'
import getBusiness from '../../domain/src/business/getBusiness'
import { useEffect } from 'react'

const BASE_CLASS = 'me-navbar'

export default function Navbar({ showLinks = true }) {
  const { business, setBusiness } = useBusinessContext()

  useEffect(() => {
    if (business.id.length > 0) {
    } else {
      auth.onAuthStateChanged((user) => {
        if (user) {
          getBusiness({
            businessId: user.uid,
          }).then((business) => {
            setBusiness(business.data)
          })
        }
      })
    }
  }, [business, setBusiness])

  return (
    <nav className={BASE_CLASS}>
      <Link to='/' title='My-event'>
        <img
          className={`${BASE_CLASS}-logo`}
          height={50}
          width={200}
          src={logo}
          alt='My-event logo'
          title='My-event logo'
        />
      </Link>
      {showLinks && (
        <ul className={`${BASE_CLASS}-list`}>
          <li className={`${BASE_CLASS}-listItem`}>
            <Link to='/' title='Home' className={`${BASE_CLASS}-listItemLink`}>
              Inicio
            </Link>
          </li>
          <li className={`${BASE_CLASS}-listItem`}>
            <Link
              to='/about'
              title='¿Como funciona?'
              className={`${BASE_CLASS}-listItemLink`}
            >
              ¿Cómo funciona?
            </Link>
          </li>
          <li className={`${BASE_CLASS}-listItem`}>
            <Link
              to='/plans'
              title='Nuestros planes'
              className={`${BASE_CLASS}-listItemLink`}
            >
              Nuestros planes
            </Link>
          </li>
          {!business.name ? (
            <Link to='/login' title='Iniciar sesión'>
              <button className={`${BASE_CLASS}-listItemButton`}>
                Iniciar sesión
              </button>
            </Link>
          ) : (
            <Link
              to='/dashboard'
              title='Ir al dashboard'
              className={`${BASE_CLASS}-listItemLink name`}
            >
              <img
                src={business.logo}
                height={25}
                width={25}
                alt='Logo de la empresa'
                className={`${BASE_CLASS}-listItemLogo`}
              />
              <p className={`${BASE_CLASS}-listItemName`}>{business.name}</p>
            </Link>
          )}
        </ul>
      )}
    </nav>
  )
}
