import { useLocation, useParams } from 'react-router-dom'
import './index.scss'
import logo from '../../assets/logos/my-event-logo.svg'
import { atcb_action } from 'add-to-calendar-button'
import Button from '../../components/Button'
import moment from 'moment'
import { useEffect, useState } from 'react'
import getBookingDetail from '../../domain/src/booking/getBookingData'
import LoadingPage from '../LoadingPage'

const BASE_CLASS = 'me-bookingPageConfirmed'

export default function BookingPageConfirmed() {
  const { businessId, bookingId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const { state } = location
  const [booking, setBooking] = useState(state ? state.booking : null)
  const business = state ? state.business : null

  useEffect(() => {
    if (!booking) {
      getBookingDetail({
        bookingId,
        businessId,
      }).then((res) => {
        setIsLoading(false)
        setBooking(res.data)
      })
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div className={BASE_CLASS}>
      <img
        src={logo}
        alt='booking confirmed'
        className={`${BASE_CLASS}-logo`}
      />
      <div className={`${BASE_CLASS}-card`}>
        <h1 className={`${BASE_CLASS}-card-title`}>Reserva confirmada</h1>
        <p className={`${BASE_CLASS}-card-subtitle`}>
          Dia {moment(booking.date).format('DD/MM/YYYY')} a las{' '}
          {booking.startTime &&
            moment(booking.startTime, 'HH:mm').format('HH:mm').toString()}{' '}
        </p>
        <p className={`${BASE_CLASS}-card-subtitle`}>
          Tu reserva ha sido confirmada con éxito guarda este código QR o el
          enlace para consultar tu reserva o añade un recordatorio a tu
          calendario.
        </p>
      </div>
      <div className={`${BASE_CLASS}-qr`}>
        <img
          src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://my-event.es/booking/${businessId}/${bookingId}`}
          alt='qr code'
        />
      </div>
      <div className={`${BASE_CLASS}-calendar`}>
        <Button
          onClick={() =>
            atcb_action({
              name: `Reserva en ${business.name}`,
              description: '',
              location: business.address,
              startDate: moment(booking.date).toISOString().slice(0, 10),
              startTime: booking.startTime
                ? moment(booking.startTime, 'HH:mm').format('HH:mm').toString()
                : moment(booking.date).format('HH:mm').toString(),
              endTime: moment.startTime
                ? moment(booking.startTime, 'HH:mm')
                    .add(
                      booking.products.reduce((acc, product) => {
                        return acc + product.time
                      }, 0),
                      'minutes'
                    )
                    .format('HH:mm')
                    .toString()
                : moment(booking.date)
                    .add(
                      booking.products.reduce((acc, product) => {
                        return acc + product.time
                      }, 0),
                      'minutes'
                    )
                    .format('HH:mm')
                    .toString(),
              options: ['Google', 'iCal|Añadir a iCal'],
              timeZone: 'currentBrowser',
            })
          }
        >
          Añadir recordatorio
        </Button>
      </div>
    </div>
  )
}
