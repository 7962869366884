import './index.scss'

const BASE_CLASS = 'me-joinUsBanner'

export default function JoinUsBanner() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-text`}>
        <h2 className={`${BASE_CLASS}-title`}>
          ¡Empieza hoy mismo y forma parte de my-event!
        </h2>
        <p className={`${BASE_CLASS}-description`}>
          ¿Tienes un negocio y te has quedado con dudas sobre como podemos
          ayudarte? ¡Solicita una demo sin compromiso!
        </p>
        <button className={`${BASE_CLASS}-button`}>Solicita una demo</button>
      </div>
    </div>
  )
}
