import './index.scss'
import { useEffect, useState } from 'react'
import myEventLogo from '../../../assets/logos/my-event-logo.svg'
import Button, { BUTTON_TYPES } from '../../../components/Button'
import { useBusinessContext } from '../../../context/BusinessContext/businessContext'
import { useNavigate } from 'react-router-dom'
import uploadImage from '../../../domain/src/storage/uploadImage'
import createBusiness from '../../../domain/src/business/createBusiness'

const BASE_CLASS = 'me-setupPageImages'

export default function SetupPageImages() {
  const [isLoading, setIsLoading] = useState(false)
  const [logo, setLogo] = useState(false)
  const [backgroundImage, setBackgroundImage] = useState()
  const [logoPreview, setLogoPreview] = useState()
  const [backgroundImagePreview, setBackgroundImagePreview] = useState()
  const { business, setBusiness, validateBusinessIsComplete } =
    useBusinessContext()
  const navigate = useNavigate()

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const logoUrl = await uploadImage(logo, 'business')
      const backgroundImageUrl = await uploadImage(backgroundImage, 'business')
      setBusiness({
        ...business,
        logo: logoUrl,
        backgroundImage: backgroundImageUrl,
      })

      if (!validateBusinessIsComplete()) {
        await createBusiness({
          ...business,
          logo: logoUrl,
          backgroundImage: backgroundImageUrl,
        })
          .then((res) => {
            if (res.status === 200) {
              navigate('/setup/complete')
            }
            setIsLoading(false)
          })
          .catch((err) => {
            setIsLoading(false)
          })
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!logo) {
      setLogoPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(logo)
    setLogoPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [logo])

  useEffect(() => {
    if (!backgroundImage) {
      setBackgroundImagePreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(backgroundImage)
    setBackgroundImagePreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [backgroundImage])

  return (
    <div className={BASE_CLASS}>
      <form className={`${BASE_CLASS}-form`}>
        <img src={myEventLogo} alt='logo' className={`${BASE_CLASS}-logo`} />
        <p>¡Vamos a darle un poco de color a tu negocio!</p>
        <label className={`${BASE_CLASS}-form-label`} htmlFor='name'>
          Logo
        </label>
        <input
          className={`${BASE_CLASS}-form-input`}
          type='file'
          alt='phone'
          id='phone'
          onChange={(e) => {
            e.preventDefault()
            setLogo(e.target.files[0])
          }}
        />
        <div className={`${BASE_CLASS}-form-imageContainer`}>
          {logoPreview && (
            <img
              height={100}
              width={100}
              src={logoPreview}
              alt='logo'
              className={`${BASE_CLASS}-logo`}
            />
          )}
        </div>
        <label className={`${BASE_CLASS}-form-label`} htmlFor='type'>
          Imagen de fondo
        </label>
        <input
          className={`${BASE_CLASS}-form-input`}
          type='file'
          alt='backgroundImage'
          id='backgroundImage'
          onChange={(e) => {
            e.preventDefault()
            setBackgroundImage(e.target.files[0])
          }}
        />
        <div className={`${BASE_CLASS}-form-backgroundImageContainer`}>
          {backgroundImagePreview && (
            <img
              src={backgroundImagePreview}
              alt='backgroundImage'
              className={`${BASE_CLASS}-backgroundImage`}
            />
          )}
        </div>
        <div className={`${BASE_CLASS}-form-buttonsContainer`}>
          <Button
            onClick={() => {
              navigate('/setup/info')
            }}
            buttonType={BUTTON_TYPES.OUTLINE}
          >
            Volver
          </Button>

          <Button onClick={handleFormSubmit} buttonType={BUTTON_TYPES.OUTLINE}>
            Finalizar
          </Button>
        </div>
      </form>
      {isLoading && (
        <div className={`${BASE_CLASS}-loading`}>
          <div class={`${BASE_CLASS}-loading-lds-ring`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  )
}
