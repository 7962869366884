import { useAuthStatus } from './hooks/useAuthStatus'
import SignInPage from './pages/AuthFlows/SignIn'
import LoadingPage from './pages/LoadingPage'

export default function ProtectedRouteHandler({ children }) {
  const { loggedIn, checkingStatus } = useAuthStatus()

  return (
    <>
      {
        // display a spinner while auth status being checked
        checkingStatus ? (
          <LoadingPage />
        ) : loggedIn ? (
          // if user is logged in, grant the access to the route
          // note: in this example component is Bar
          children
        ) : (
          // else render an unauthorised component
          // stating the reason why it cannot access the route
          <SignInPage />
        )
      }
    </>
  )
}
