import './index.scss'
import { useBusinessContext } from '../../context/BusinessContext/businessContext'
import { useEffect, useState } from 'react'
import LoadingPage from '../LoadingPage'
import { auth } from '../../domain/src/config/firebase'
import getBusiness from '../../domain/src/business/getBusiness'
import Button from '../../components/Button'
import userSignOut from '../../domain/src/auth/signOut'
const BASE_CLASS = 'me-dashboardPage'

export default function DashboardPage() {
  const [isLoading, setIsLoading] = useState(true)
  const { business, setBusiness } = useBusinessContext()

  useEffect(() => {
    if (business.id.length > 0) {
      setIsLoading(false)
    } else {
      auth.onAuthStateChanged((user) => {
        if (user) {
          getBusiness({
            businessId: user.uid,
          }).then((business) => {
            setBusiness(business.data)
            setIsLoading(false)
          })
        }
      })
    }
  }, [business, setBusiness])

  return !isLoading ? (
    <div className={BASE_CLASS}>
      <h1>Bienvenido, {business.name}</h1>
      <Button
        onClick={() => {
          userSignOut()
        }}
      >
        Cerrar sesión
      </Button>
    </div>
  ) : (
    <LoadingPage />
  )
}
