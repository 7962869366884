import { useEffect, useState } from 'react'

import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../domain/src/config/firebase'

export const useAuthStatus = () => {
  // assume user to be logged out
  const [loggedIn, setLoggedIn] = useState(false)

  // keep track to display a spinner while auth status is being checked
  const [checkingStatus, setCheckingStatus] = useState(true)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true)
      }

      setCheckingStatus(false)
    })
  }, [])

  return { loggedIn, checkingStatus }
}
