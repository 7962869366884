import { createContext, useContext, useState } from 'react'

const BusinessContext = createContext()

function useBusinessContext() {
  return useContext(BusinessContext)
}

function BusinessContextProvider({ children }) {
  const [business, setBusiness] = useState({
    id: '',
    name: '',
    type: '',
    email: '',
    schedule: {},
    phone: '',
    address: '',
    description: '',
    logo: '',
    backgroundImage: '',
  })

  const validateBusinessIsComplete = () => {
    const {
      id,
      name,
      type,
      schedule,
      phone,
      address,
      description,
      logo,
      backgroundImage,
    } = business
    return (
      id &&
      name &&
      type &&
      schedule &&
      phone &&
      address &&
      description &&
      logo &&
      backgroundImage
    )
  }

  const setBusinessId = (id) => {
    setBusiness({ ...business, id })
  }

  const setBusinessName = (name) => {
    setBusiness({ ...business, name })
  }

  const setBusinessType = (type) => {
    setBusiness({ ...business, type })
  }

  const setBusinessEmail = (email) => {
    setBusiness({ ...business, email })
  }

  const setBusinessSchedule = (schedule) => {
    setBusiness({ ...business, schedule })
  }

  const setBusinessPhone = (phone) => {
    setBusiness({ ...business, phone })
  }

  const setBusinessAddress = (address) => {
    setBusiness({ ...business, address })
  }

  const setBusinessDescription = (description) => {
    setBusiness({ ...business, description })
  }

  const setBusinessLogo = (logo) => {
    setBusiness({ ...business, logo })
  }

  const setBusinessBackgroundImage = (backgroundImage) => {
    setBusiness({ ...business, backgroundImage })
  }

  return (
    <BusinessContext.Provider
      value={{
        business,
        setBusiness,
        setBusinessId,
        setBusinessName,
        setBusinessType,
        setBusinessEmail,
        setBusinessSchedule,
        setBusinessPhone,
        setBusinessAddress,
        setBusinessDescription,
        setBusinessLogo,
        setBusinessBackgroundImage,
        validateBusinessIsComplete,
      }}
    >
      {children}
    </BusinessContext.Provider>
  )
}

export { BusinessContext, BusinessContextProvider, useBusinessContext }
