import './index.scss'

import Navbar from '../../components/Navbar'
import WelcomeBanner from '../../components/WelcomeBanner'
import { Suspense, useRef, useState } from 'react'
import TypeSelector from '../../components/TypeSelector'
import ClientContent from './ClientContent'
import UserContent from './UserContent'
import SharedFooter from '../../components/SharedFooter'
import JoinUsBanner from '../../components/JoinUsBanner'
import ReviewBanner from '../../components/ReviewBanner'

import { ReactComponent as QuestionIcon } from '../../assets/icons/question.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'

import useIntersection from '../../hooks/useIntersection'

const BASE_CLASS = 'me-homePage'

export default function HomePage() {
  const [isUser, setIsUser] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const selectorReference = useRef(null)
  const joinUsBannerReference = useRef()
  const reviewBannerReference = useRef()

  const displayJoinUsBanner = useIntersection(joinUsBannerReference)
  const displayReviewBanner = useIntersection(reviewBannerReference)

  const onMoreInfoButtonClick = () => {
    selectorReference.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className={BASE_CLASS}>
      <Navbar />
      <WelcomeBanner onMoreInfoButtonClick={onMoreInfoButtonClick} />
      <TypeSelector
        selectorReference={selectorReference}
        isUser={isUser}
        setIsUser={setIsUser}
      />
      {isUser ? <UserContent /> : <ClientContent />}
      <div
        className={`${BASE_CLASS}-reviewBannerPlaceholder`}
        ref={reviewBannerReference}
      >
        {displayReviewBanner && (
          <Suspense fallback={null}>
            <ReviewBanner />
          </Suspense>
        )}
      </div>
      <div
        className={`${BASE_CLASS}-joinUsBannerPlaceholder`}
        ref={joinUsBannerReference}
      >
        {displayJoinUsBanner && (
          <Suspense fallback={null}>
            <JoinUsBanner />
          </Suspense>
        )}
      </div>
      {showModal && (
        <div className={`${BASE_CLASS}-contactModal`}>
          <CloseIcon
            onClick={() => setShowModal(false)}
            className={`${BASE_CLASS}-contactModal-closeIcon`}
            height={24}
            width={24}
            fill='grey'
          />
          <span>
            Hey! Nos encanta verte por aquí. ¿Tienes alguna duda?
            <a
              className={`${BASE_CLASS}-contactModal-link`}
              href='mailto:myevent.es.info@gmail.com'
            >
              Escríbenos
            </a>
          </span>
        </div>
      )}
      <button
        onClick={() => setShowModal(!showModal)}
        className={`${BASE_CLASS}-helpButton`}
      >
        <div className={`${BASE_CLASS}-helpButton-object`}></div>
        <QuestionIcon height={32} width={32} fill='#FF9200' />
      </button>
      <SharedFooter />
    </div>
  )
}
