import './index.scss'
import visibilityImage from '../../assets/images/people.jpeg'

const BASE_CLASS = 'me-visibilityBanner'

export default function VisibilityBanner() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-text`}>
        <h2 className={`${BASE_CLASS}-text-title`}>Muestra lo que ofreces</h2>
        <p className={`${BASE_CLASS}-text-description`}>
          Queremos que tus clientes puedan ver todos los servicios que realizáis
          y escoger con los profesionales que quieren recibir ese servicio, por
          eso damos la posibilidad de añadir tantos servicios y productos como
          tengas en tu centro.
        </p>
      </div>
      <img
        src={visibilityImage}
        alt='My-event connect network'
        title='My-event connect network'
        className={`${BASE_CLASS}-image`}
      />
    </div>
  )
}
