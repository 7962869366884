import './index.scss'
import introductionClientBannerMockup from '../../assets/images/mockup-business-functionality.png'

const BASE_CLASS = 'me-introductionClientBanner'

export default function IntroductionClientBanner() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-text`}>
        <h2 className={`${BASE_CLASS}-text-title`}>
          Automatiza tus reservas y dedica más tiempo a tu trabajo con nuestra
          app de gestión de reservas.
        </h2>
        <p className={`${BASE_CLASS}-text-description`}>
          Con nuestra aplicación, podrás enfocarte en tu verdadera pasión y
          dejar de perder tiempo en la gestión de mensajes, llamadas y correos
          electrónicos. Automatiza todo este proceso y dedica tu tiempo a lo que
          realmente importa. Ahorra tiempo y aumenta la eficiencia de tu negocio
          con nuestra herramienta de gestión de reservas.
        </p>
      </div>
      <img
        src={introductionClientBannerMockup}
        alt='My-event mockup'
        title='My-event mockup'
        className={`${BASE_CLASS}-image`}
      />
    </div>
  )
}
