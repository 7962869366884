import './index.scss'

const BASE_CLASS = 'me-button'

export const BUTTON_TYPES = {
  DEFAULT: 'default',
  OUTLINE: 'outline',
  LINK: 'link',
}

export default function Button({
  children,
  onClick,
  buttonType = BUTTON_TYPES.DEFAULT,
}) {
  return (
    <button
      className={`${BASE_CLASS} ${buttonType}`}
      onClick={onClick}
      type={buttonType}
    >
      {children}
    </button>
  )
}
