import { config } from '../config/index'

export default async function getFreeSlots({
  bookingDuration = 30,
  workerId,
  date,
  businessId,
  workerSchedule,
}) {
  return fetch(`${config.baseURl}/free-slots`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      workerId,
      bookingDuration,
      date: date.toISOString(),
      businessId,
      workerSchedule,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 404) {
        return {
          status: 404,
          message: 'Free slots not found',
          data: {},
        }
      }

      return data
    })
}
