import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import BusinessPage from './pages/BusinessPage'
import ConfirmBookingPage from './pages/BookingPage'
import HomePage from './pages/HomePage'
import BookingPageConfirmed from './pages/BookingPageConfirmed'
import LoadingPage from './pages/LoadingPage'
import SignUpPage from './pages/AuthFlows/SignUp'
import SignInPage from './pages/AuthFlows/SignIn'
import SetupPage from './pages/SetupFlows/Setup'
import ForgotPassword from './pages/AuthFlows/ForgotPassword'
import SetupPageInfo from './pages/SetupFlows/SetupInfo'
import SetupPageImages from './pages/SetupFlows/SetupImages'
import SetupPageComplete from './pages/SetupFlows/SetupComplete'
import DashboardPage from './pages/Dashboard'
import ProtectedRouteHandler from './protectedRouteHandler'
import PrivacyPage from './pages/PrivacyPage'

export default function RouterHandler() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: 'about',
      element: <div>About</div>,
    },
    {
      path: 'plans',
      element: <div>Plans</div>,
    },
    {
      path: 'business/:businessId',
      element: <BusinessPage />,
    },
    {
      path: 'bookingConfirmed/:businessId/:bookingId',
      element: <BookingPageConfirmed />,
    },
    {
      path: 'business/:businessId/book',
      element: <ConfirmBookingPage />,
    },
    {
      path: 'loading',
      element: <LoadingPage />,
    },
    {
      path: '/register',
      element: <SignUpPage />,
    },
    {
      path: '/login',
      element: <SignInPage />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/privacy',
      element: <PrivacyPage />,
    },
    {
      path: '/setup',
      element: <SetupPage />,
    },
    {
      path: '/setup/info',
      element: <SetupPageInfo />,
    },
    {
      path: '/setup/images',
      element: <SetupPageImages />,
    },
    {
      path: '/setup/complete',
      element: <SetupPageComplete />,
    },
    {
      path: '/dashboard',
      element: (
        <ProtectedRouteHandler>
          <DashboardPage />
        </ProtectedRouteHandler>
      ),
    },
  ])

  return <RouterProvider router={router} />
}
