import './index.scss'

import timeImage from '../../assets/images/time.jpg'

const BASE_CLASS = 'me-lessTimeBanner'

export default function LessTimeBanner() {
  return (
    <div className={BASE_CLASS}>
      <img
        src={timeImage}
        alt='My-event mockup'
        title='My-event mockup'
        className={`${BASE_CLASS}-image`}
      />
      <div className={`${BASE_CLASS}-text`}>
        <h2 className={`${BASE_CLASS}-text-title`}>
          El tiempo es oro, ¡lo sabemos!
        </h2>
        <p className={`${BASE_CLASS}-text-description`}>
          En My-event no queremos hacerte perder el tiempo, por eso apostamos
          para que únicamente veas y tengas guardados los centros estéticos que
          tú deseas.
        </p>
      </div>
    </div>
  )
}
