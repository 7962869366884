export const WEEK_DAYS = [
  'Lunes',
  'Martes',
  'Miércole',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domigo',
]

export const WEEK_DAYS_SHORT = ['L', 'M', 'M', 'J', 'V', 'S', 'D']

export const DEFAULT_SCHEDULE = {
  0: [{ start: '00:00', end: '00:00' }],
  1: [{ start: '00:00', end: '00:00' }],
  2: [{ start: '00:00', end: '00:00' }],
  3: [{ start: '00:00', end: '00:00' }],
  4: [{ start: '00:00', end: '00:00' }],
  5: [{ start: '00:00', end: '00:00' }],
  6: [{ start: '00:00', end: '00:00' }],
  7: [{ start: '00:00', end: '00:00' }],
}
