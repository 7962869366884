import './index.scss'
import logo from '../../assets/logos/my-event-logo.svg'
const BASE_CLASS = 'me-loading-page'

export default function LoadingPage() {
  return (
    <div className={BASE_CLASS}>
      <img className={`${BASE_CLASS}-logo`} src={logo} alt='logo' />
      <div className={`${BASE_CLASS}-spinner`} />
    </div>
  )
}
