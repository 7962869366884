import './index.scss'

const BASE_CLASS = 'me-stayInContactBanner'

export default function StayInContactBanner() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-text`}>
        <h2 className={`${BASE_CLASS}-text-title`}>
          Estaremos encantados de estar siempre en contacto
        </h2>
        <p className={`${BASE_CLASS}-text-description`}>
          Por esa misma razón tenemos un equipo que estará a tu disposición en
          cualquier momento que lo necesites para consultas o dificultades.
        </p>
      </div>
      <img
        src='https://images.unsplash.com/photo-1525182008055-f88b95ff7980?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
        alt='My-event mockup'
        title='My-event mockup'
        className={`${BASE_CLASS}-image`}
      />
    </div>
  )
}
