import './index.scss'
import logo from '../../../assets/logos/my-event-logo.svg'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as CloseEye } from '../../../assets/icons/eye-close.svg'
import { ReactComponent as OpenEye } from '../../../assets/icons/eye-open.svg'
import { useState } from 'react'
import signUp from '../../../domain/src/auth/signUp'

const BASE_CLASS = 'me-signUpPage'

export default function SignUpPage() {
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)
  const [error, setError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [repeatPasswordError, setRepeatPasswordError] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const validateForm = () => {
    const isEmailValid =
      email.length > 0 &&
      email.includes('@') &&
      email.includes('.') &&
      email.length > 5
    const isPasswordValid = password.length > 8
    const isRepeatPasswordValid = repeatPassword.length > 8
    const isTermsAcceptedValid = isTermsAccepted
    const isPasswordEqual = password === repeatPassword
    isEmailValid ? setEmailError(false) : setEmailError(true)
    isPasswordValid ? setPasswordError(false) : setPasswordError(true)
    isRepeatPasswordValid
      ? setRepeatPasswordError(false)
      : setRepeatPasswordError(true)
    isTermsAcceptedValid ? setTermsError(false) : setTermsError(true)
    isPasswordEqual
      ? setRepeatPasswordError(false)
      : setRepeatPasswordError(true)
    return (
      isEmailValid &&
      isPasswordValid &&
      isRepeatPasswordValid &&
      isTermsAcceptedValid &&
      isPasswordEqual
    )
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    const validated = validateForm()
    if (validated) {
      await signUp({
        email,
        password,
      })
        .then((res) => {
          setIsLoading(false)
          setError(false)
          navigate('/setup')
        })
        .catch((err) => {
          if (error.code === 'auth/email-already-in-use') {
            setEmailError(true)
          }
          setIsLoading(false)
          setError(true)
        })
    }
    setIsLoading(false)
  }

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-container`}>
        <img src={logo} alt='logo' className={`${BASE_CLASS}-logo`} />
        <h1 className={`${BASE_CLASS}-title`}>Reigstrate</h1>
        <form className={`${BASE_CLASS}-form`}>
          <p className={`${BASE_CLASS}-form-label`}>
            Tu dirección de correo electrónico
          </p>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='example@gmail.com'
            type='email'
            className={`${BASE_CLASS}-form-input`}
          />
          <div className={`${BASE_CLASS}-form-error-container`}>
            {emailError && (
              <p className={`${BASE_CLASS}-form-error-label`}>
                Por favor, introduce un email válido
              </p>
            )}
          </div>
          <p className={`${BASE_CLASS}-form-label`}>Contraseña</p>
          <div className={`${BASE_CLASS}-form-passwordContainer`}>
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder='min 8 caracteres'
              type={showPassword ? 'text' : 'password'}
              className={`${BASE_CLASS}-form-input`}
            />
            {showPassword ? (
              <CloseEye
                width={24}
                height={24}
                className={`${BASE_CLASS}-form-passwordContainer-icon`}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <OpenEye
                width={24}
                height={24}
                className={`${BASE_CLASS}-form-passwordContainer-icon`}
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
          <div className={`${BASE_CLASS}-form-error-container`}>
            {passwordError && (
              <p className={`${BASE_CLASS}-form-error-label`}>
                Por favor, introduce una contraseña válida
              </p>
            )}
          </div>
          <p className={`${BASE_CLASS}-form-label`}>Repite la contraseña</p>
          <div className={`${BASE_CLASS}-form-passwordContainer`}>
            <input
              onChange={(e) => setRepeatPassword(e.target.value)}
              value={repeatPassword}
              placeholder='min 8 caracteres'
              type={showRepeatPassword ? 'text' : 'password'}
              className={`${BASE_CLASS}-form-input`}
            />
            {showRepeatPassword ? (
              <CloseEye
                width={24}
                height={24}
                className={`${BASE_CLASS}-form-passwordContainer-icon`}
                onClick={() => setShowRepeatPassword(false)}
              />
            ) : (
              <OpenEye
                width={24}
                height={24}
                className={`${BASE_CLASS}-form-passwordContainer-icon`}
                onClick={() => setShowRepeatPassword(true)}
              />
            )}
          </div>
          <div className={`${BASE_CLASS}-form-error-container`}>
            {repeatPasswordError && (
              <p className={`${BASE_CLASS}-form-error-label`}>
                Las contraseñas no coinciden
              </p>
            )}
          </div>
          <div className={`${BASE_CLASS}-form-terms`}>
            <input
              type='checkbox'
              checked={isTermsAccepted}
              onChange={(e) => setIsTermsAccepted(e.target.checked)}
              className={`${BASE_CLASS}-form-terms-checkbox`}
            />
            <p className={`${BASE_CLASS}-form-terms-text`}>
              Acepto los <Link to='/terms'>términos y condiciones</Link>
            </p>
          </div>
          <div className={`${BASE_CLASS}-form-error-container`}>
            {termsError && (
              <p className={`${BASE_CLASS}-form-error-label`}>
                Debes aceptar los términos y condiciones
              </p>
            )}
          </div>
          <button
            onClick={handleSubmit}
            className={`${BASE_CLASS}-form-button`}
          >
            Regístrame
          </button>
          <button
            onClick={() => navigate('/login')}
            className={`${BASE_CLASS}-form-button-outline`}
          >
            Ya tengo cuenta
          </button>
          {error && (
            <p className={`${BASE_CLASS}-form-error-label-submit`}>
              Ha ocurrido un error, por favor, inténtalo de nuevo
            </p>
          )}
        </form>
      </div>
      {isLoading && (
        <div className={`${BASE_CLASS}-loading`}>
          <div class={`${BASE_CLASS}-loading-lds-ring`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  )
}
