import './index.scss'

import { ReactComponent as Logo } from '../../assets/icons/star.svg'

const BASE_CLASS = 'me-reviewBanner'

export default function ReviewBanner() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-imageContainer`}>
        <img
          src='https://images.unsplash.com/photo-1503951914875-452162b0f3f1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
          alt='My event review'
          title='My event review'
          className={`${BASE_CLASS}-image`}
        />
      </div>
      <div className={`${BASE_CLASS}-review`}>
        <p className={`${BASE_CLASS}-review-text`}>
          "My-event es una herramienta muy útil para gestionar eventos. Es muy
          intuitiva y fácil de usar. Además, el equipo de soporte es muy
          profesional y resuelve todas las dudas que puedas tener."
        </p>
        <div className={`${BASE_CLASS}-review-stars`}>
          <Logo fill='#FBBA33' height={24} />
          <Logo fill='#FBBA33' height={24} />
          <Logo fill='#FBBA33' height={24} />
          <Logo fill='#FBBA33' height={24} />
          <Logo fill='#FBBA33' height={24} />
        </div>

        <p className={`${BASE_CLASS}-review-author`}>Peluqueria Ramón</p>
        <p className={`${BASE_CLASS}-review-business`}>
          Peluqueria Unisex en Mataró
        </p>
      </div>
    </div>
  )
}
