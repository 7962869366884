import { config } from '../config/index'

export default async function getBusinessWorkers({ businessId }) {
  return fetch(`${config.baseURl}/workers/all?businessId=${businessId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 404) {
        return {
          status: 404,
          message: 'Business not found',
          data: {},
        }
      }

      return data
    })
}
