import IntroductionClientBanner from '../../../components/IntroductionClientBanner'
import EasyToUserBanner from '../../../components/EasyToUserBanner'
import VisibilityBanner from '../../../components/VisibilityBanner'
import StayInContactBanner from '../../../components/StayInContactBanner'

import { Suspense, useRef } from 'react'
import useIntersection from '../../../hooks/useIntersection'

const BASE_CLASS = 'me-clientContent'

export default function ClientContent() {
  const stayInContactBannerReference = useRef()

  const displayStayInContactBanner = useIntersection(
    stayInContactBannerReference
  )

  return (
    <div className={BASE_CLASS}>
      <IntroductionClientBanner />
      <EasyToUserBanner />
      <VisibilityBanner />
      <div
        className={`${BASE_CLASS}-stayInContactBannerPlaceholder`}
        ref={stayInContactBannerReference}
      >
        <Suspense fallback={null}>
          {displayStayInContactBanner && <StayInContactBanner />}
        </Suspense>
      </div>
    </div>
  )
}
