import moment from 'moment'
import { config } from '../config/index'

export default async function createBooking({ bookingData }) {
  const bookingTime = bookingData.products.reduce(
    (acc, product) => acc + product.time,
    0
  )
  const slotsBooked = bookingTime / 15

  const bookedSlots = generateBookedSlots({
    startTime: bookingData.startTime,
    slotsBooked,
  })

  return fetch(`${config.baseURl}/bookings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      bookingData: {
        id: new Date().getTime(),
        duration: bookingTime,
        ...bookingData,
      },
      bookedSlots,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 404) {
        return {
          status: 404,
          message: 'Business not found',
          data: {},
        }
      }
      return data
    })
}

function generateBookedSlots({ startTime, slotsBooked }) {
  const start = moment(startTime, 'HH:mm')
  let previosSlot = start
  const bookedSlots = []

  for (let i = 0; i < slotsBooked; i++) {
    bookedSlots.push(previosSlot.format('HH:mm'))
    const nextSlot = previosSlot.add(15, 'minutes')
    previosSlot = nextSlot
  }

  return bookedSlots
}
