import './index.scss'

const BASE_CLASS = 'me-setupPageComplete'

export default function SetupPageComplete() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}__content`}>
        <h1 className={`${BASE_CLASS}__title`}>Setup Complete!</h1>
        <p className={`${BASE_CLASS}__subtitle`}>
          You're all set up and ready to go! You can always come back to this
          page to change your settings.
        </p>
      </div>
    </div>
  )
}
