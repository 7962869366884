import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyD2vjUJX5JeCsUb2HjoCJtDT8b7vmDGgvE',
  authDomain: 'my-event-mvp.firebaseapp.com',
  projectId: 'my-event-mvp',
  storageBucket: 'my-event-mvp.appspot.com',
  messagingSenderId: '699737459604',
  appId: '1:699737459604:web:8a5b4347699851bbe6dd81',
  measurementId: 'G-LTYV8TEQF7',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth()
const storage = getStorage()
export { app, analytics, auth, storage }
