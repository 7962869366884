import './index.scss'

import { useEffect, useState } from 'react'
import { WEEK_DAYS_SHORT } from './config'
import Button, { BUTTON_TYPES } from '../../components/Button'

const baseClass = 'me-schedulePicker'

export default function SchedulePicker({
  schedule = {},
  handleUpdateSchedule,
}) {
  const [newSchedule, setNewSchedule] = useState(schedule)
  const [selectedDays, setSelectedDays] = useState([])

  useEffect(() => {
    handleUpdateSchedule(newSchedule)
  }, [handleUpdateSchedule, newSchedule])

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-content`}>
        <div className={`${baseClass}-content-body`}>
          <div className={`${baseClass}-content-body-header`}>
            {WEEK_DAYS_SHORT.map((day, index) => (
              <div
                onClick={() => {
                  if (selectedDays.includes(index)) {
                    setSelectedDays(
                      selectedDays.filter((item) => item !== index)
                    )
                  } else {
                    setSelectedDays([...selectedDays, index])
                    setNewSchedule({
                      ...newSchedule,
                      [index]: [
                        {
                          start: '00:00',
                          end: '00:00',
                        },
                      ],
                    })
                  }
                }}
                className={
                  selectedDays.includes(index)
                    ? `${baseClass}-content-body-header-day selected`
                    : `${baseClass}-content-body-header-day`
                }
              >
                <p>{day}</p>
              </div>
            ))}
          </div>
          <div className={`${baseClass}-content-body-content`}>
            {selectedDays.length > 0 &&
              newSchedule[selectedDays[0]]?.map((item, index) => (
                <div className={`${baseClass}-content-body-content-time`}>
                  <div
                    className={`${baseClass}-content-body-content-time-inputContainer`}
                  >
                    <p>Abre</p>
                    <input
                      type='time'
                      value={newSchedule[selectedDays[0]][index].start}
                      onChange={(event) => {
                        const newScheduleCopy = { ...newSchedule }
                        selectedDays.forEach((day) => {
                          newScheduleCopy[day][index].start = event.target.value
                        })
                        setNewSchedule(newScheduleCopy)
                      }}
                    />
                  </div>
                  <div
                    className={`${baseClass}-content-body-content-time-inputContainer`}
                  >
                    <p>Cierra</p>
                    <input
                      type='time'
                      value={newSchedule[selectedDays[0]][index].end}
                      onChange={(event) => {
                        const newScheduleCopy = { ...newSchedule }
                        selectedDays.forEach((day) => {
                          newScheduleCopy[day][index].end = event.target.value
                        })
                        setNewSchedule(newScheduleCopy)
                      }}
                    />
                  </div>
                  <Button
                    buttonType={BUTTON_TYPES.OUTLINE}
                    onClick={(e) => {
                      e.preventDefault()
                      const newScheduleCopy = { ...newSchedule }
                      selectedDays.forEach((day) => {
                        newScheduleCopy[day].pop()
                      })
                      setNewSchedule(newScheduleCopy)
                    }}
                  >
                    Eliminar
                  </Button>
                </div>
              ))}
          </div>
          {selectedDays.length > 0 && (
            <div className={`${baseClass}-content-body-addButton`}>
              <Button
                buttonType={BUTTON_TYPES.LINK}
                onClick={(e) => {
                  e.preventDefault()
                  const newScheduleCopy = { ...newSchedule }
                  selectedDays.forEach((day) => {
                    newScheduleCopy[day] !== undefined
                      ? newScheduleCopy[day].push({
                          start: '00:00',
                          end: '00:00',
                        })
                      : (newScheduleCopy[day] = [
                          {
                            start: '00:00',
                            end: '00:00',
                          },
                        ])
                  })
                  setNewSchedule(newScheduleCopy)
                }}
              >
                <p>Agregar horario</p>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
