import './index.scss'
import logo from '../../../assets/logos/my-event-logo.svg'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import recoverPassword from '../../../domain/src/auth/recoverPassword'

const BASE_CLASS = 'me-signInPage'

export default function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const validateForm = () => {
    const isEmailValid =
      email.length > 0 &&
      email.includes('@') &&
      email.includes('.') &&
      email.length > 5
    isEmailValid ? setEmailError(false) : setEmailError(true)

    return isEmailValid
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    const validated = validateForm()
    if (validated) {
      await recoverPassword({
        email,
      })
        .then((res) => {
          console.log(res)
          setIsLoading(false)
          setError(false)
          setShowModal(true)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
          setError(true)
        })
    }
    setIsLoading(false)
  }

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-container`}>
        <img src={logo} alt='logo' className={`${BASE_CLASS}-logo`} />
        <h1 className={`${BASE_CLASS}-title`}>Recuperar contraseña</h1>
        <form className={`${BASE_CLASS}-form`}>
          <p className={`${BASE_CLASS}-form-label`}>Correo electrónico</p>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='example@gmail.com'
            type='email'
            className={`${BASE_CLASS}-form-input`}
          />
          <div className={`${BASE_CLASS}-form-error-container`}>
            {emailError && (
              <p className={`${BASE_CLASS}-form-error-label`}>
                Por favor, introduce un email válido
              </p>
            )}
          </div>
          <button
            onClick={handleSubmit}
            className={`${BASE_CLASS}-form-button`}
          >
            Recuperar contraseña
          </button>
          <button
            onClick={() => navigate('/login')}
            className={`${BASE_CLASS}-form-button-outline`}
          >
            Ya dispongo de una cuenta
          </button>
          {error && (
            <p className={`${BASE_CLASS}-form-error-label-submit`}>
              Parece que no hemos podido encontrar tu cuenta. Por favor, revisa
              tu email e inténtalo de nuevo.
            </p>
          )}
        </form>
      </div>
      {isLoading && (
        <div className={`${BASE_CLASS}-loading`}>
          <div class={`${BASE_CLASS}-loading-lds-ring`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {showModal && (
        <div className={`${BASE_CLASS}-modal`}>
          <div className={`${BASE_CLASS}-modal-container`}>
            <h1 className={`${BASE_CLASS}-modal-title`}>
              ¡Te hemos enviado un email!
            </h1>
            <p className={`${BASE_CLASS}-modal-text`}>
              Revisa tu bandeja de entrada y sigue las instrucciones para
              recuperar tu contraseña.
            </p>
            <button
              onClick={() => navigate('/login')}
              className={`${BASE_CLASS}-form-button-outline`}
            >
              Volver a inicio de sesión
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
