import './index.scss'
import { useState } from 'react'
import logo from '../../../assets/logos/my-event-logo.svg'
import SchedulePicker from '../../../components/SchedulePicker'
import Button, { BUTTON_TYPES } from '../../../components/Button'
import { useBusinessContext } from '../../../context/BusinessContext/businessContext'
import { useNavigate } from 'react-router-dom'
import { BUSINESS_TYPES } from './config'
const BASE_CLASS = 'me-setupPage'

export default function SetupPage() {
  const { business, setBusiness } = useBusinessContext()
  const [name, setName] = useState(business.name || '')
  const [errorName, setErrorName] = useState(false)
  const [scheduleError, setScheduleError] = useState(false)
  const [type, setType] = useState(business.type || BUSINESS_TYPES[0])
  const [schedule, setSchedule] = useState(business.schedule || {})
  const navigate = useNavigate()

  const handleFormSubmit = (e) => {
    e.preventDefault()
    const hasErrorName = name.length < 8
    const hasErrorSchedule = Object.keys(schedule).length === 0
    if (hasErrorName) setErrorName(true)
    if (!hasErrorName) setErrorName(false)
    if (hasErrorSchedule) setScheduleError(true)
    if (!hasErrorSchedule) setScheduleError(false)
    if (hasErrorName || hasErrorSchedule) return
    setBusiness({ ...business, name, type, schedule })
    navigate('/setup/info')
  }

  return (
    <div className={BASE_CLASS}>
      <form className={`${BASE_CLASS}-form`}>
        <img src={logo} alt='logo' className={`${BASE_CLASS}-logo`} />
        <p>¡Cuéntanos un poco acerca de tu negocio!</p>
        <label className={`${BASE_CLASS}-form-label`} htmlFor='name'>
          Nombre del negocio
        </label>
        <input
          className={
            errorName
              ? `${BASE_CLASS}-form-input error`
              : `${BASE_CLASS}-form-input`
          }
          type='text'
          name='name'
          id='name'
          placeholder='min. 8 carácteres'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errorName && (
          <p className={`${BASE_CLASS}-form-error-label`}>Indica un nombre</p>
        )}
        <label className={`${BASE_CLASS}-form-label`} htmlFor='type'>
          Tipo de negocio
        </label>
        <select
          className={`${BASE_CLASS}-form-input`}
          name='type'
          id='type'
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          {BUSINESS_TYPES.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>

        <label className={`${BASE_CLASS}-form-label`} htmlFor='schedule'>
          Horario de atención
        </label>
        <p>Selecciona los dias de los que quieres modificar los horarios</p>
        {scheduleError && (
          <p className={`${BASE_CLASS}-form-error-label`}>Indica un horario</p>
        )}
        <SchedulePicker
          schedule={schedule}
          handleUpdateSchedule={setSchedule}
        />
        <div className={`${BASE_CLASS}-form-buttonContainer`}>
          <Button onClick={handleFormSubmit} buttonType={BUTTON_TYPES.OUTLINE}>
            Siguiente
          </Button>
        </div>
      </form>
    </div>
  )
}
