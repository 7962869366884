import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../config/firebase'

export default async function signIn({ email, password }) {
  return signInWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      const user = userCredential.user
      return user
    }
  )
}
