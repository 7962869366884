import './index.scss'

import noWorriesImage from '../../assets/images/no-worries.jpg'

const BASE_CLASS = 'me-noWorriesBanner'

export default function NoWorriesBanner() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-text`}>
        <h2 className={`${BASE_CLASS}-text-title`}>
          No te preocupes por tu día a día
        </h2>
        <p className={`${BASE_CLASS}-text-description`}>
          No te preocupes en recordar la cita, nosotros te enviamos una
          notificación para que no la olvides y no te la pierdas.
        </p>
      </div>
      <img
        src={noWorriesImage}
        alt='My-event mockup'
        title='My-event mockup'
        className={`${BASE_CLASS}-image`}
      />
    </div>
  )
}
