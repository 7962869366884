import './index.scss'

const BASE_CLASS = 'me-typeSelector'

export default function TypeSelector({ selectorReference, isUser, setIsUser }) {
  return (
    <div ref={selectorReference} className={`${BASE_CLASS}`}>
      <button
        className={
          !isUser ? `selected ${BASE_CLASS}-button` : `${BASE_CLASS}-button`
        }
        onClick={() => setIsUser(false)}
      >
        Negocios
      </button>
      <button
        className={
          isUser ? `selected ${BASE_CLASS}-button` : `${BASE_CLASS}-button`
        }
        onClick={() => setIsUser(true)}
      >
        Para tus clientes
      </button>
    </div>
  )
}
