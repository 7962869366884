import { ReactComponent as WifiIcon } from '../../assets/icons/wifi.svg'
import { ReactComponent as ParkingIcon } from '../../assets/icons/parking.svg'
import { ReactComponent as PetIcon } from '../../assets/icons/pet.svg'
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card.svg'

export const SCHEDULETRANSLATIONS = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
}

export const SERVICES = {
  wifi: {
    id: 'wifi',
    name: 'Wifi',
    component: <WifiIcon fill='orange' height={16} width={16} />,
  },
  parking: {
    id: 'parking',
    name: 'Parking',
    component: <ParkingIcon fill='orange' height={16} width={16} />,
  },
  pets: {
    id: 'pets',
    name: 'Mascotas',
    component: <PetIcon fill='orange' height={16} width={16} />,
  },
  creditCard: {
    id: 'creditCard',
    name: 'Tarjeta',
    component: <CreditCardIcon fill='orange' height={16} width={16} />,
  },
}
