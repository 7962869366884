import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../config/firebase'

export default async function signIn({ email, password }) {
  return sendPasswordResetEmail(auth, email, password).then(
    (userCredential) => {
      return userCredential
    }
  )
}
