import './index.scss'
import logo from '../../../assets/logos/my-event-logo.svg'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CloseEye } from '../../../assets/icons/eye-close.svg'
import { ReactComponent as OpenEye } from '../../../assets/icons/eye-open.svg'
import { useState } from 'react'
import signIn from '../../../domain/src/auth/signIn'
import getBusiness from '../../../domain/src/business/getBusiness'
import { useBusinessContext } from '../../../context/BusinessContext/businessContext'

const BASE_CLASS = 'me-signInPage'

export default function SignInPage() {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { setBusiness } = useBusinessContext()
  const navigate = useNavigate()

  const validateForm = () => {
    const isEmailValid =
      email.length > 0 &&
      email.includes('@') &&
      email.includes('.') &&
      email.length > 5
    const isPasswordValid = password.length > 7
    isEmailValid ? setEmailError(false) : setEmailError(true)
    isPasswordValid ? setPasswordError(false) : setPasswordError(true)

    return isEmailValid && isPasswordValid
  }

  const handleSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    const validated = validateForm()
    if (validated) {
      await signIn({
        email,
        password,
      })
        .then(async (res) => {
          console.log(res)
          setIsLoading(false)
          setError(false)
          return getBusiness({
            businessId: res.uid,
          }).then((res) => {
            console.log(res.data)
            setBusiness(res.data)
            if (res.status === 404) {
              navigate('/setup')
            } else {
              navigate('/dashboard')
            }
          })
        })
        .catch((err) => {
          console.log(err)
          if (error.code === 'auth/email-already-in-use') {
            setEmailError(true)
          }
          setIsLoading(false)
          setError(true)
        })
    }
    setIsLoading(false)
  }

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-container`}>
        <img src={logo} alt='logo' className={`${BASE_CLASS}-logo`} />
        <h1 className={`${BASE_CLASS}-title`}>Inicia sesión en tu cuenta</h1>
        <form className={`${BASE_CLASS}-form`}>
          <p className={`${BASE_CLASS}-form-label`}>Correo electrónico</p>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='example@gmail.com'
            type='email'
            className={`${BASE_CLASS}-form-input`}
          />
          <div className={`${BASE_CLASS}-form-error-container`}>
            {emailError && (
              <p className={`${BASE_CLASS}-form-error-label`}>
                Por favor, introduce un email válido
              </p>
            )}
          </div>
          <p className={`${BASE_CLASS}-form-label`}>Contraseña</p>
          <div className={`${BASE_CLASS}-form-passwordContainer`}>
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder='min 8 caracteres'
              type={showPassword ? 'text' : 'password'}
              className={`${BASE_CLASS}-form-input`}
            />
            {showPassword ? (
              <CloseEye
                width={24}
                height={24}
                className={`${BASE_CLASS}-form-passwordContainer-icon`}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <OpenEye
                width={24}
                height={24}
                className={`${BASE_CLASS}-form-passwordContainer-icon`}
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
          <div className={`${BASE_CLASS}-form-error-container`}>
            {passwordError && (
              <p className={`${BASE_CLASS}-form-error-label`}>
                Por favor, introduce una contraseña válida
              </p>
            )}
          </div>
          <p
            onClick={() => navigate('/forgot-password')}
            className={`${BASE_CLASS}-form-forgotPassword`}
          >
            ¿Olvidaste tu contraseña?
          </p>
          <button
            onClick={handleSubmit}
            className={`${BASE_CLASS}-form-button`}
          >
            Iniciar sesión
          </button>
          <button
            onClick={() => navigate('/register')}
            className={`${BASE_CLASS}-form-button-outline`}
          >
            No tengo cuenta
          </button>
          {error && (
            <p className={`${BASE_CLASS}-form-error-label-submit`}>
              Ha ocurrido un error, por favor, inténtalo de nuevo
            </p>
          )}
        </form>
      </div>
      {isLoading && (
        <div className={`${BASE_CLASS}-loading`}>
          <div class={`${BASE_CLASS}-loading-lds-ring`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  )
}
