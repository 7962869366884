import './index.scss'
import { useState } from 'react'
import logo from '../../../assets/logos/my-event-logo.svg'
import Button, { BUTTON_TYPES } from '../../../components/Button'
import { useBusinessContext } from '../../../context/BusinessContext/businessContext'
import { useNavigate } from 'react-router-dom'
import { SERVICES } from './config'
const BASE_CLASS = 'me-setupPageInfo'

export default function SetupPageInfo() {
  const { business, setBusiness } = useBusinessContext()
  const [phone, setPhone] = useState(business.phone || '')
  const [address, setAddress] = useState(business.address || '')
  const [description, setDescription] = useState(business.description || '')
  const [selectedServices, setSelectedServices] = useState(
    business.services || []
  )
  const [errorPhone, setErrorPhone] = useState(false)
  const [errorAddress, setErrorAddress] = useState(false)
  const [errorDescription, setErrorDescription] = useState(false)
  const navigate = useNavigate()

  const handleFormSubmit = (e) => {
    e.preventDefault()
    const hasErrorPhone = phone.length < 9
    const hasErrorAddress = address.length < 1
    const hasErrorDescription = description.length < 1

    if (hasErrorPhone) setErrorPhone(true)
    if (!hasErrorPhone) setErrorPhone(false)
    if (hasErrorAddress) setErrorAddress(true)
    if (!hasErrorAddress) setErrorAddress(false)
    if (hasErrorDescription) setErrorDescription(true)
    if (!hasErrorDescription) setErrorDescription(false)
    if (hasErrorPhone || hasErrorAddress || hasErrorDescription) return

    setBusiness({
      ...business,
      phone,
      address,
      description,
      services: selectedServices,
    })
    navigate('/setup/images')
  }

  return (
    <div className={BASE_CLASS}>
      <form className={`${BASE_CLASS}-form`}>
        <img src={logo} alt='logo' className={`${BASE_CLASS}-logo`} />
        <p>¡Ofrece a tus clientes mas información acerca de tu negocio!</p>
        <label className={`${BASE_CLASS}-form-label`} htmlFor='name'>
          Teléfono de contacto
        </label>
        <input
          className={
            errorPhone
              ? `${BASE_CLASS}-form-input error`
              : `${BASE_CLASS}-form-input`
          }
          type='text'
          name='phone'
          id='phone'
          placeholder='666 666 666'
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        {errorPhone && (
          <p className={`${BASE_CLASS}-form-error-label`}>Indica un teléfono</p>
        )}
        <label className={`${BASE_CLASS}-form-label`} htmlFor='type'>
          Dirección del negocio
        </label>
        <input
          className={
            errorAddress
              ? `${BASE_CLASS}-form-input error`
              : `${BASE_CLASS}-form-input`
          }
          type='text'
          name='address'
          id='address'
          placeholder='ej. Calle 1'
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        {errorAddress && (
          <p className={`${BASE_CLASS}-form-error-label`}>
            Indica una dirección
          </p>
        )}
        <label className={`${BASE_CLASS}-form-label`} htmlFor='schedule'>
          Descripción del negocio
        </label>
        <textarea
          className={
            errorDescription
              ? `${BASE_CLASS}-form-textarea error`
              : `${BASE_CLASS}-form-textarea`
          }
          type='text'
          name='description'
          id='description'
          placeholder='max. 200 carácteres'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {errorDescription && (
          <p className={`${BASE_CLASS}-form-error-label`}>
            Indica una descripción
          </p>
        )}
        <div className={`${BASE_CLASS}-form-textarea-count`}>
          <span
            className={
              description.length > 200
                ? `${BASE_CLASS}-form-textarea-count-span error`
                : `${BASE_CLASS}-form-textarea-count-span`
            }
          >
            {description.length}/200
          </span>
        </div>
        <label className={`${BASE_CLASS}-form-label`} htmlFor='schedule'>
          Selecciona los servicios que ofreces
        </label>
        <div className={`${BASE_CLASS}-form-services`}>
          {SERVICES.map(({ name, component, id }) => {
            return (
              <div
                className={
                  selectedServices.includes(id)
                    ? `${BASE_CLASS}-form-services-item selected`
                    : `${BASE_CLASS}-form-services-item`
                }
                onClick={() => {
                  console.log(selectedServices)
                  if (selectedServices.includes(id)) {
                    setSelectedServices(
                      selectedServices.filter((service) => service !== id)
                    )
                  } else {
                    setSelectedServices([...selectedServices, id])
                  }
                }}
              >
                {component}
                <label className={`${BASE_CLASS}-form-services-item-name`}>
                  {name}
                </label>
              </div>
            )
          })}
        </div>
        <div className={`${BASE_CLASS}-form-buttonContainer`}>
          <Button
            onClick={() => {
              navigate('/setup')
            }}
            buttonType={BUTTON_TYPES.OUTLINE}
          >
            Volver
          </Button>
          <Button onClick={handleFormSubmit} buttonType={BUTTON_TYPES.OUTLINE}>
            Siguiente
          </Button>
        </div>
      </form>
    </div>
  )
}
