import './index.scss'

import fastBookingImage from '../../assets/images/fast-booking.jpg'

const BASE_CLASS = 'me-easyBookingBanner'

export default function EasyBookingBanner() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-text`}>
        <h2 className={`${BASE_CLASS}-text-title`}>
          Reserva sin complicaciones
        </h2>
        <p className={`${BASE_CLASS}-text-description`}>
          ¿Quieres hacer una reserva en tu centro estético habitual? Con
          My-event nunca ha sido tan fácil. Rápido, sencillo, a cualquier hora o
          lugar, ¡y gratis! Se acabaron las llamadas telefónicas en horarios
          concretos. Reserva desde ¡YA! En tus centros favoritos.
        </p>
      </div>
      <img
        src={fastBookingImage}
        alt='My-event mockup'
        title='My-event mockup'
        className={`${BASE_CLASS}-image`}
      />
    </div>
  )
}
