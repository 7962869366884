import Navbar from '../../components/Navbar'
import SharedFooter from '../../components/SharedFooter'
import './index.scss'

const BASE_CLASS = 'me-privacyPage'

export default function PrivacyPage() {
  return (
    <div className={BASE_CLASS}>
      <Navbar showLinks={false} />
      <div className={`${BASE_CLASS}-content`}>
        <h1 className={`${BASE_CLASS}-title`}>Política de privacidad</h1>
        <h2 className={`${BASE_CLASS}-subtitle`}>POLÍTICA DE PRIVACIDAD WEB</h2>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          Protección de datos de carácter personal según la LOPD
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          MY-EVENT S.L., en aplicación de la normativa vigente en materia de
          protección de datos de carácterpersonal, informa que los datos
          personales que se recogen a través de los formularios del Sitio web:
          https://my-event.es , se incluyen en los ficheros automatizados
          específicos de usuarios de los servicios de MY-EVENT S.L. La recogida
          y tratamiento automatizado de los datos de carácter personal tiene
          como finalidad el mantenimiento de la relación comercial y el
          desempeño de tareas de información, formación, asesoramiento y otras
          actividades propias de MY-EVENT S.L. Estos datos únicamente serán
          cedidos a aquellas entidades que sean necesarias con el único objetivo
          de dar cumplimiento a la finalidad anteriormente expuesta. MY-EVENT
          S.L. adopta las medidas necesarias para garantizar la seguridad,
          integridad y confidencialidad de los datos conforme a lo dispuesto en
          el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de
          27 de abril de 2016, relativo a la protección de las personas físicas
          en lo que respecta al tratamiento de datos personales y a la libre
          circulación de los mismos. El usuario podrá en cualquier momento
          ejercitar los derechos de acceso, oposición, rectificación y
          cancelación reconocidos en el citado Reglamento (UE). El ejercicio de
          estos derechos puede realizarlo el propio usuario a través del email
          my.es.info@gmail.com El usuario manifiesta que todos los datos
          facilitados por él son ciertos y correctos, y se compromete a
          mantenerlos actualizados, comunicando los cambios a MY-EVENT S.L.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          Finalidad del tratamiento de los datos personales:
        </h3>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          ¿Con qué finalidad trataremos tus datos personales?
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          En MY-EVENT S.L., trataremos tus datos personales recabados a través
          del Sitio Web: https://my-event.es, con las siguientes finalidades: 1.
          En caso de contratación de los bienes y servicios ofertados a través
          de MY-EVENT S.L. paramantener la relación contractual, así como la
          gestión, administración, información, prestación y mejora del
          servicio. 2. Envío de información solicitada a través de los
          formularios dispuestos en https://my-event.es 3. Remitir boletines
          (newsletters), así como comunicaciones comerciales de promociones y/o
          publicidad de MY-EVENT S.L. y del sector. Te recordamos que puedes
          oponerte al envío de comunicaciones comerciales por cualquier vía y en
          cualquier momento, remitiendo un correo electrónico a la dirección
          indicada anteriormente. Los campos de dichos registros son de
          cumplimentación obligatoria, siendo imposible realizar las finalidades
          expresadas si no se aportan esos datos.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          ¿Por cuánto tiempo se conservan los datos personales recabados?
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          Los datos personales proporcionados se conservarán mientras se
          mantenga la relación comercial o no solicites su supresión y durante
          el plazo por el cuál pudieran derivarse responsabilidades legales por
          los servicios prestados.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>Legitimación:</h3>
        <p className={`${BASE_CLASS}-text`}>
          El tratamiento de tus datos se realiza con las siguientes bases
          jurídicas que legitiman el mismo: 1. La solicitud de información y/o
          la contratación de los servicios de MY-EVENT S.L., cuyos términos y
          condiciones se pondrán a tu disposición en todo caso, de forma previa
          a una eventual contratación. 2. El consentimiento libre, específico,
          informado e inequívoco, en tanto que te informamos poniendo a tu
          disposición la presente política de privacidad, que tras la lectura de
          la misma, en caso de estar conforme, puedes aceptar mediante una
          declaración o una clara acción afirmativa, como el marcado de una
          casilla dispuesta al efecto. En caso de que no nos facilites tus datos
          o lo hagas de forma errónea o incompleta, no podremos atender tu
          solicitud, resultando del todo imposible proporcionarte la información
          solicitada o llevar a cabo la contratación de los servicios.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>Destinatarios:</h3>
        <p className={`${BASE_CLASS}-text`}>
          Los datos no se comunicarán a ningún tercero ajeno a MY-EVENT S.L.,
          salvo obligación legal.
        </p>
        <h2 className={`${BASE_CLASS}-subtitle`}>
          POLÍTICA DE PRIVACIDAD PARA APLICACIONES MÓVILES (APPS)
        </h2>
        <p className={`${BASE_CLASS}-text`}>
          Fecha última actualización:09/05/ 2023 De conformidad con el
          Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27
          de abril de 2016, relativo a la protección de las personas físicas en
          lo que respecta al tratamiento de datos personales y a la libre
          circulación de estos datos (Reglamento General de Protección de Datos
          - RGPD), XXXEMPRESAXXX, informa a los usuarios de laaplicación(en
          adelante, la Aplicación), acerca del tratamiento de los datos
          personales, que ellos voluntariamente hayan facilitado durante el
          proceso de registro, acceso y utilización del servicio.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          1. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO.
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          MY-Event S.L. es la entidadresponsable del tratamiento de los datos
          facilitados por los clientes de la Aplicación (en adelante, el/los
          Usuario/s).
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          2. FINALIDAD DEL TRATAMIENTO DE DATOS.
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          Para proceder al registro, acceso y posterior uso de la Aplicación, el
          Usuario deberá facilitar -de forma voluntaria-, datos de carácter
          personal (esencialmente, identificativos y de contacto), los cuales
          serán incorporados a soportes automatizados titularidad de MY-Event
          S.L. La recogida, almacenamiento, modificación, estructuración y en su
          caso, eliminación, de los datos proporcionados por los Usuarios,
          constituirán operaciones de tratamiento llevadas a cabo por el
          Responsable, con la finalidad de garantizar el correcto funcionamiento
          de la Aplicación, mantener la relación de prestación de servicios y/o
          comercial con el Usuario, y para la gestión, administración,
          información, prestación y mejora del servicio. Los datos personales
          facilitados por el Usuario -especialmente, el correo electrónico o
          e-mail- podrán emplearse también para remitir boletines (newsletters),
          así como comunicaciones comerciales de promociones y/o publicidad de
          la Aplicación,siempre y cuando, el Usuario haya prestado previamente
          su consentimiento expreso para la recepción de estas comunicaciones
          vía electrónica.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>3. LEGITIMACIÓN.</h3>
        <p className={`${BASE_CLASS}-text`}>
          El tratamiento de los datos del Usuario, se realiza con las siguientes
          bases jurídicas que legitiman el mismo: • La solicitud de información
          y/o la contratación de los servicios de la Aplicación, cuyos términos
          y condiciones se pondrán a disposición del Usuario en todo caso, con
          carácter previo, para su expresa aceptación. • El consentimiento
          libre, específico, informado e inequívoco del Usuario, poniendo a su
          disposición la presente política de privacidad, que deberá aceptar
          mediante una declaración o una clara acción afirmativa, como el
          marcado de una casilla dispuesta al efecto. En caso de que el Usuario
          no facilite a MY-Event S.L. sus datos, o lo haga de forma errónea o
          incompleta, no será posible proceder al uso de la Aplicación.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          4. CONSERVACIÓN DE LOS DATOS PERSONALES.
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          Los datos personales proporcionados por el Usuario, se conservarán en
          los sistemas y bases de datos del Responsable del Tratamiento,
          mientras aquél continúe haciendo uso de la Aplicación, y siempre que
          no solicite su supresión. Con el objetivo de depurar las posibles
          responsabilidades derivadas del tratamiento, los datos se conservarán
          por un período mínimo de cinco años.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>5. DESTINATARIOS.</h3>
        <p className={`${BASE_CLASS}-text`}>
          Los datos no se comunicarán a ningún tercero ajeno a MY-EVENT S.L.,
          salvo obligación legal o en cualquier caso, previa solicitud del
          consentimiento del Usuario. De otra parte, MY-EVENT S.L. podrá dar
          acceso o transmitir los datos personales facilitados por el Usuario, a
          terceros proveedores de servicios, con los que haya suscrito acuerdos
          de encargo de tratamiento de datos, y que únicamente accedan a dicha
          información para prestar un servicio en favor y por cuenta del
          Responsable.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          6. RETENCIÓN DE DATOS.
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          MY-EVENT S.L., informa al Usuario de que, como prestador de servicio
          de alojamiento de datos y en virtud de lo establecido en la Ley
          34/2002 de 11 de julio de Servicios de la Sociedad de la Información y
          de Comercio Electrónico (LSSI), retiene por un período máximo de 12
          meses la información imprescindible para identificar el origen de los
          datos alojados y elmomento en que se inició la prestación del
          servicio. La retención de estos datos no afecta al secreto de las
          comunicaciones y sólo podrán ser utilizados en el marco de una
          investigación criminal o para la salvaguardia de la seguridad pública,
          poniéndose a disposición de los jueces y/o tribunales o del Ministerio
          que así los requiera. La comunicación de datos a las Fuerzas y Cuerpos
          de Seguridad del Estado, se hará en virtud de lo dispuesto por la
          normativa sobre protección de datos personales, y bajo el máximo
          respeto a la misma.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          7. PROTECCIÓN DE LA INFORMACIÓN ALOJADA.
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          El Responsable del Tratamiento, adopta las medidas necesarias para
          garantizar la seguridad, integridad y confidencialidad de los datos
          conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento
          Europeo y del Consejo, de 27 de abril de 2016, relativo a la
          protección de las personas físicas en lo que respecta al tratamiento
          de datos personales y a la libre circulación de los mismos. Si bien el
          Responsable, realiza copias de seguridad de los contenidos alojados en
          sus servidores, sin embargo no se responsabiliza de la pérdida o el
          borrado accidental de los datos por parte de los Usuarios. De igual
          manera, no garantiza la reposición total de los datos borrados por los
          Usuarios, ya que los citados datos podrían habersido suprimidos y/o
          modificados durante el periodo de tiempo transcurrido desde la última
          copia de seguridad. Los servicios facilitados o prestados a través de
          la Aplicación, excepto los servicios específicos de backup, no
          incluyen la reposición de los contenidos conservados en las copias de
          seguridad realizadas por el Responsable del Tratamiento, cuando esta
          pérdida sea imputable al usuario; en este caso, se determinará una
          tarifa acorde a la complejidad y volumen de la recuperación, siempre
          previa aceptación del usuario. La reposición de datos borrados sólo
          está incluida en el precio delservicio cuando la pérdida del contenido
          sea debida a causas atribuibles al Responsable.
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          8. EJERCICIO DE DERECHOS.
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          MY-EVENT S.L., informa al Usuario de que le asisten los derechos de
          acceso, rectificación, limitación, supresión, oposición y
          portabilidad, los cuales podrá ejercitar mediante petición dirigida al
          correo electrónico: myevent.es.info@gmail.com Asimismo, el Usuario
          tiene derecho a revocar el consentimiento inicialmente prestado, y a
          interponer reclamaciones de derechos frente a la Agencia Española de
          Protección de Datos (AEPD).
        </p>
        <h3 className={`${BASE_CLASS}-subtitle-header`}>
          9. COMUNICACIONES COMERCIALES POR VÍA ELECTRÓNICA.
        </h3>
        <p className={`${BASE_CLASS}-text`}>
          En aplicación de la LSSI (Ley de Servicios de la Sociedad de la
          Información), MY-EVENT S.L., no enviará comunicaciones publicitarias o
          promocionales por correo electrónico u otro medio de comunicación
          electrónica equivalente que previamente no hubieran sido solicitadas o
          expresamente autorizadas por los destinatarios de las mismas. En el
          caso de usuarios con los que exista una relación contractual, jurídica
          o de servicios previa, el Responsable del Tratamiento, sí está
          autorizado al envío de comunicaciones comerciales referentes a
          productos o servicios del Responsable que sean similares a los que
          inicialmente fueron objeto de contratación con el cliente. En caso de
          que el Usuario quiera darse de baja a la hora de recibir las citadas
          comunicaciones, podrá hacerlo remitiendo su voluntad por e-mail al
          correo electrónico: myevent.es.info@gmail.com.
        </p>
      </div>
      <SharedFooter />
    </div>
  )
}
