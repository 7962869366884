import './index.scss'

import { Link } from 'react-router-dom'
import { ReactComponent as InstagramLogo } from '../../assets/icons/instagram.svg'
import myEventLogo from '../../assets/logos/my-event-logo.svg'

const BASE_CLASS = 'me-sharedFooter'

export default function SharedFooter() {
  return (
    <footer className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-navigation`}>
        <img
          className={`${BASE_CLASS}-logo`}
          src={myEventLogo}
          alt='My-event logo'
          title='My-event logo'
        />
        <ul className={`${BASE_CLASS}-navigationList`}>
          <li className={`${BASE_CLASS}-navigationList-item`}>
            <Link to='/'>Inicio</Link>
          </li>
          <li className={`${BASE_CLASS}-navigationList-item`}>
            <Link to='/'>¿Como funciona?</Link>
          </li>
          <li className={`${BASE_CLASS}-navigationList-item`}>
            <Link to='/'>Nuestros planes</Link>
          </li>
          <li className={`${BASE_CLASS}-navigationList-item`}>
            <Link to='/privacy'>Política de privacidad</Link>
          </li>
        </ul>
        <div className={`${BASE_CLASS}-social`}>
          <InstagramLogo height={24} fill='#FF9200' />
        </div>
      </div>

      <span className={`${BASE_CLASS}-copy`}>Copyright © 2022 My-Event</span>
    </footer>
  )
}
