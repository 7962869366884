/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import createBooking from '../../domain/src/booking/createBooking'
import getBusinessWorkers from '../../domain/src/business/getBusinessWorkers'
import getFreeSlots from '../../domain/src/workers/getFreeSlots'
import workerImg from '../../assets/icons/person.svg'
import LoadingPage from '../LoadingPage'
import './index.scss'

const BASE_CLASS = 'me-bookingPage'

export default function BookingPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location
  const { products, business } = state
  const { businessId } = useParams()
  const [workers, setWorkers] = useState([])
  const [date, setDate] = useState(new Date())
  const [observations, setObservations] = useState('')
  const [inputDate, setInputDate] = useState(
    `${date.getFullYear()}-${
      date.getMonth().toString().length === 1
        ? `0${date.getMonth() + 1}`
        : date.getMonth()
    }-${
      date.getDate().toString().length === 1
        ? `0${date.getDate()}`
        : date.getDate()
    }`
  )
  const [selectedWorker, setSelectedWorker] = useState(null)
  const [freeSlots, setFreeSlots] = useState([])
  const [fetchingFreeSlots, setFetchingFreeSlots] = useState(false)
  const [selectedTime, setSelectedTime] = useState(null)
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [formError, setFormError] = useState({
    name: false,
    email: false,
    worker: false,
    time: false,
  })

  const handleSelectWorker = (workerId) => {
    setSelectedWorker(workerId)
  }

  const handleSelectTime = (time) => {
    setSelectedTime(time)
    let newDate = date
    newDate.setHours(time.split(':')[0], time.split(':')[1])
    setDate(newDate)
  }

  useEffect(() => {
    if (workers.length > 0) {
      setSelectedWorker(workers[0].id)
    }
  }, [workers])

  const handleCreateBooking = () => {
    setIsLoading(true)
    const formError = {
      name: false,
      email: false,
      worker: false,
      time: false,
    }
    if (name.length === 0) {
      formError.name = true
    }
    if (phone.length === 0) {
      formError.phone = true
    }
    if (!selectedWorker) {
      formError.worker = true
    }
    if (!selectedTime) {
      formError.time = true
    }
    if (
      name.length === 0 ||
      phone.length === 0 ||
      !selectedWorker ||
      !selectedTime
    ) {
      setIsLoading(false)
      setFormError(formError)
      return
    }

    let bookingDate = new Date(date)
    bookingDate.setHours(selectedTime.split(':')[0], selectedTime.split(':')[1])
    const bookingData = {
      name: name,
      phone: phone,
      date: bookingDate,
      workerId: selectedWorker,
      status: 'pending',
      businessId: businessId,
      products: products,
      startTime: selectedTime,
      observations: observations,
    }

    createBooking({
      bookingData,
    }).then((data) => {
      console.log(data.data)
      setIsLoading(false)
      if (data.status === 200) {
        navigate(`/bookingConfirmed/${businessId}/${data.data.bookingId}`, {
          state: {
            booking: { ...bookingData, id: data.data.bookingId },
            business,
          },
        })
      } else {
        alert('Error al crear la reserva')
      }
    })
  }

  useEffect(() => {
    if (selectedWorker) {
      setFetchingFreeSlots(true)
      getFreeSlots({
        bookingDuration: products.reduce(
          (acc, product) => acc + product.time,
          0
        ),
        businessId: businessId,
        workerId: selectedWorker,
        date: date,
        workerSchedule: workers.find((worker) => worker.id === selectedWorker)
          ?.schedule,
      })
        .then((data) => {
          setFetchingFreeSlots(false)
          setFreeSlots(data.data)
        })
        .catch((err) => {
          setFetchingFreeSlots(false)
        })
    }
  }, [selectedWorker, date])

  useEffect(() => {
    getBusinessWorkers({
      businessId: businessId,
    }).then((data) => {
      setWorkers(data.data)
    })
  }, [])

  return workers.length > 0 ? (
    <div className={BASE_CLASS}>
      {isLoading && (
        <div className={`${BASE_CLASS}-loading`}>
          <div className={`${BASE_CLASS}-loading-spinner`}></div>
        </div>
      )}
      <div className={`${BASE_CLASS}-header`}>
        <div className={`${BASE_CLASS}-header-container`}>
          <div className={`${BASE_CLASS}-header-info`}>
            <h1 className={`${BASE_CLASS}-title`}>Nueva reserva</h1>
            <h2 className={`${BASE_CLASS}-subtitle`}>{business.name}</h2>
          </div>
          <div className={`${BASE_CLASS}-header-actions`}>
            <button
              onClick={() => navigate(-1)}
              className={`${BASE_CLASS}-header-actions-button cancel`}
            >
              Cancelar
            </button>
            <button
              onClick={() => handleCreateBooking()}
              className={`${BASE_CLASS}-header-actions-button`}
            >
              Reservar
            </button>
          </div>
        </div>
      </div>
      <div className={`${BASE_CLASS}-content`}>
        <div className={`${BASE_CLASS}-main`}>
          <div className={`${BASE_CLASS}-productsContainer`}>
            <h3 className={`${BASE_CLASS}-workers-title`}>Tus servicios</h3>
            <div className={`${BASE_CLASS}-products`}>
              {products.map((product) => (
                <div className={`${BASE_CLASS}-product`} key={product.id}>
                  <p className={`${BASE_CLASS}-product-name`}>{product.name}</p>
                  <p className={`${BASE_CLASS}-product-price`}>
                    {product.price}€
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={`${BASE_CLASS}-dateContainer`}>
            <h2 className={`${BASE_CLASS}-dateContainer-title`}>Tus datos</h2>
            <div className={`${BASE_CLASS}-clientInfo-name`}>
              <h3 className={`${BASE_CLASS}-clientInfo-title`}>Nombre</h3>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={
                  formError.name
                    ? `${BASE_CLASS}-input error`
                    : `${BASE_CLASS}-input`
                }
                placeholder='Nombre'
                type='text'
              />
              {formError.name && (
                <p className={`${BASE_CLASS}-error-span`}>
                  El nombre es obligatorio
                </p>
              )}
            </div>
            <div className={`${BASE_CLASS}-clientInfo-phone`}>
              <h3 className={`${BASE_CLASS}-clientInfo-title`}>Teléfono</h3>
              <input
                className={
                  formError.phone
                    ? `${BASE_CLASS}-input error`
                    : `${BASE_CLASS}-input`
                }
                value={phone}
                placeholder='Teléfono'
                type='number'
                onChange={(e) => {
                  e.target.value.length <= 9 && setPhone(e.target.value)
                }}
              />
              {formError.phone && (
                <p className={`${BASE_CLASS}-error-span`}>
                  El correo es obligatorio
                </p>
              )}
            </div>
            <h3 className={`${BASE_CLASS}-clientInfo-title`}>
              Elije el trabajador
            </h3>
            {formError.worker && (
              <p className={`${BASE_CLASS}-error-span`}>
                El trabajador es obligatorio
              </p>
            )}
            <div className={`${BASE_CLASS}-workers`}>
              {workers.map((worker) => (
                <div
                  onClick={() => handleSelectWorker(worker.id)}
                  className={`${BASE_CLASS}-worker`}
                  key={worker.id}
                >
                  <img
                    className={
                      worker.id === selectedWorker
                        ? `${BASE_CLASS}-worker-image selected`
                        : `${BASE_CLASS}-worker-image`
                    }
                    src={
                      worker.profilePicture ? worker.profilePicture : workerImg
                    }
                    alt='worker'
                  />
                  <p className={`${BASE_CLASS}-worker-name`}>{worker.name}</p>
                </div>
              ))}
            </div>

            <div className={`${BASE_CLASS}-timeContainer`}>
              <div className={`${BASE_CLASS}-timeContainer-day`}>
                <h3 className={`${BASE_CLASS}-clientInfo-title`}>
                  Elije el dia
                </h3>
                <div className={`${BASE_CLASS}-date`}>
                  {inputDate && (
                    <input
                      className={`${BASE_CLASS}-date-input`}
                      type='date'
                      min={new Date().toISOString().split('T')[0]}
                      value={inputDate}
                      onChange={(e) => {
                        setInputDate(e.target.value)
                        setDate(new Date(e.target.value))
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={`${BASE_CLASS}-timeContainer-hour`}>
                <h3 className={`${BASE_CLASS}-clientInfo-title`}>
                  Elije la hora
                </h3>
                {formError.time && (
                  <p className={`${BASE_CLASS}-error-span`}>
                    La hora es obligatoria
                  </p>
                )}
                {freeSlots.length > 0 ? (
                  fetchingFreeSlots ? (
                    <div className={`${BASE_CLASS}-spinnerContainer`}>
                      <div className={`${BASE_CLASS}-spinner`} />
                    </div>
                  ) : (
                    <select
                      onChange={(e) => handleSelectTime(e.target.value)}
                      className={`${BASE_CLASS}-timeSelector`}
                    >
                      {!selectedTime && (
                        <option value=''>Selecciona una hora</option>
                      )}
                      {freeSlots?.map((slot) => (
                        <option value={slot}>{slot}</option>
                      ))}
                    </select>
                  )
                ) : (
                  <p className={`${BASE_CLASS}-no-slots-error`}>
                    No hay horas disponibles
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={`${BASE_CLASS}-observations`}>
            <h3 className={`${BASE_CLASS}-clientInfo-title`}>Observaciones</h3>
            <textarea
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
              className={`${BASE_CLASS}-observations-textarea`}
              placeholder='Datos a tener en cuenta durante el servicio'
            />
          </div>
        </div>
      </div>
      {!isLoading && (
        <div className={`${BASE_CLASS}-bookingButtonContainer`}>
          <button
            onClick={handleCreateBooking}
            className={`${BASE_CLASS}-bookingButtonContainer-button`}
          >
            Reservar
          </button>
        </div>
      )}
    </div>
  ) : (
    <LoadingPage />
  )
}
