import './index.scss'
import { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow.svg'

const BASE_CLASS = 'me-bringYourBusinessBanner'

export default function BringYourBusinessBanner() {
  return (
    <div className={BASE_CLASS}>
      <h2 className={`${BASE_CLASS}-title`}>
        ¿Tu centro de estética habitual no usa My-event?
      </h2>
      <p className={`${BASE_CLASS}-subtitle`}>
        Estaremos encantados de ayudarlos.
      </p>
      <div className={`${BASE_CLASS}-button`}>
        <a href='/'>¡Compártenos!</a>
        <RightArrowIcon
          className={`${BASE_CLASS}-buttonIcon`}
          height={24}
          fill='#3D44FF'
        />
      </div>
    </div>
  )
}
